/* Raleway */

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 100;
    src: url('Raleway-Thin.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
    color: rgb(146, 146, 146);
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 200;
    src: url('Raleway-ExtraLight.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-ExtraLight.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('Raleway-Light.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('Raleway-Regular.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('Raleway-Medium.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('Raleway-SemiBold.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('Raleway-Bold.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('Raleway-ExtraBold.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-ExtraBold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('Raleway-Black.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Raleway-Black.ttf') format('truetype'); /* Safari, Android, iOS */
}


/* YesevaOne */

@font-face {
    font-family: 'YesevaOne';
    font-style: normal;
    src: url('YesevaOne-Regular.ttf'); /* IE9 Compat Modes */
    src: local('YesevaOne'), url('YesevaOne-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Manrope */

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    src: url('Manrope-Regular.otf'); /* IE9 Compat Modes */
    src: local('Manrope'), url('Manrope-Regular.otf') format('opentype'); /* Safari, Android, iOS */
}

/* Ubuntu */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 300;
    src: url('Ubuntu-Regular.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src: url('Ubuntu-Regular.ttf');
    src: local('Raleway'), url('Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src: url('Ubuntu-Medium.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src: url('Ubuntu-Bold.ttf'); /* IE9 Compat Modes */
    src: local('Raleway'), url('Ubuntu-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}