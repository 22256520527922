.svg{
    align-self: flex-start;
}
.st0{fill:#FFFFFF;}
.st1{fill:none;stroke:#FFFFFF;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
.st2{fill:none;stroke:#4B4BFF;stroke-width:1.5;stroke-miterlimit:10;}
.st3{fill:#234BDB;}
.st4{fill:#FFA9C4;}
.st5{fill:#FFFFFF;stroke:#FFFFFF;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
.st6{fill:#302F38;stroke:#FFFFFF;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;}
.st7{fill:#FED445;}

.finger{
    animation: fingertypinganimation;
    animation-iteration-count: infinite;
}
.right-one{
    animation-duration: .3s;
}
.right-two{
    animation-duration: .35s;
}
.right-three{
    animation-duration: .25s;
}
.left-one{
    animation-duration: .25s;
}
.left-two{
    animation-duration: .35s;
}
.left-three{
    animation-duration: .30s;
}
@keyframes fingertypinganimation {
    from{ transfrom: translate(0px, 0px);}
    to{ transform: translate(0px, 0.5px);}
}