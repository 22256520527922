.parent {
    position: relative;
    height: 370px;
    z-index: 9;

}

.aligned-to-top {
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 42px 0!important;
}

.scrolled {
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 3;
    padding: 20px 0 0 0!important;
    justify-content: center;
}

.fixed-mobile-filters{
    position: fixed;
    top: 60px;
}

@media screen and (max-width: 500px) {
    .parent{
        height: 170px!important;
    }

}

.hide {
    overflow: hidden;
    height: 0px!important;
    padding: 0px!important;
    margin: 0px!important;
}

.collapse {
    height: unset;
    padding: 0px!important;
}

.liCollapse {
    height: 45px!important;
}

.checkbox-collapse {
    margin: auto 0 auto 0!important;
}

.filter-bold {
    font-weight: 800!important;
}

.computer-border, .all-border {
    border-color: #234BDB!important;
}

.see-all {
    border-bottom: 1px solid transparent;
}

.data-border {
    border-color: #FED445!important;
}

.design-border {
    border-color: #FFA9C4!important;
}

.marketing-border {
    border-color: #FE3346!important;
}

.computer-border, .data-border, .design-border, .marketing-border, .filter-bold {
    /* margin-bottom: -8px; */
}